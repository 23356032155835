import Vue from 'vue'

// axios
import axios from 'axios'

const host = process.env.NODE_ENV === 'development'
  ? 'https://placar.iabetsoficial.com.br' : 'https://placar.iabetsoficial.com.br'

const axiosIns = axios.create({
  baseURL: `${host}/api/v1/admin/`,
})

if (localStorage.token) {
  axiosIns.defaults.headers = {
    Authorization: `Bearer ${localStorage.token}`,
  }
}

Vue.prototype.$http = axiosIns

export default axiosIns
